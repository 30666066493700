import React, { useEffect } from "react";
import styled from "styled-components";
import { ANALYTICS_LOCATION, ANALYTICS_MODULE } from "constants/index";
import Flex from "app/components/Flex";
import Div from "app/components/Div";
import { H1, H3, P1, P2 } from "app/components/Typography";
import Button from "app/components/Button";
import SpanLink from "app/components/SpanLink";
import { ScreenXl } from "app/components/MediaQueries";
import { communityViewCommunityGuidelines } from "services/typewriter/segment";
import env from "helpers/env";
import { useUserAcknowledgeContent } from "./useUserAcknowledgeContent";

const StyledDialog = styled(Flex)`
  z-index: 100;
  position: absolute;
  top: 0;
  left: 0;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  -webkit-backdrop-filter: blur(8px);
  backdrop-filter: blur(8px);
  background-color: rgba(0, 0, 0, 0.5);
`;

const Wrapper = styled(Flex)`
  max-width: 1100px;
`;

const LeftPane = styled(Flex)`
  background: white;
  flex-direction: column;
  justify-content: space-between;
`;

const RightPane = styled(Div)`
  border-radius: 0 8px 8px 0;
  width: 500px;
  background: ${({ imageUrl }) => `url(${imageUrl})`} center;
  background-size: cover;
`;

const modalShouldRefresh = (prevProps: any, props: any) =>
  prevProps.isOpen === props.isOpen;

const CONTENT_MODULE = "challenges";
const CONTENT_SLUG = "terms-and-conditions";

interface Props {
  isOpen: boolean;
  showAgreeButton?: boolean;
}

const TermsAndConditionsDialog = React.memo(
  ({ isOpen, showAgreeButton }: Props) => {
    const { acknowledgeContent } = useUserAcknowledgeContent();

    useEffect(() => {
      if (isOpen) {
        communityViewCommunityGuidelines({
          location: ANALYTICS_LOCATION.community,
          module: ANALYTICS_MODULE.community_guidelines,
        });
      }
    }, [isOpen]);

    const handleClick = () => {
      acknowledgeContent({
        module: CONTENT_MODULE,
        slug: CONTENT_SLUG,
      });
    };

    if (!isOpen) {
      return null;
    }

    return (
      <StyledDialog p={0} isOpen={isOpen}>
        <Wrapper pt={{ _: "300px", xs: 0 }}>
          <LeftPane
            borderRadius={{ _: "16px", xl: "16px 0 0 16px" }}
            p={4}
            maxWidth="630px"
          >
            <Div mb={4}>
              <H1 fontSize={{ _: 5, md: 6 }} mb={3}>
                Welcome to the Community!
              </H1>
              <P1>
                This is THE space to connect with other members, get feedback,
                and get inspired! Here are some best practices to keep in mind:
              </P1>
            </Div>
            <Div mb={4}>
              <H3 mb={1}>Positive and constructive comments only</H3>
              <P1>
                This community is all about fostering growth and being
                supportive. Please stick to giving constructive advice and words
                of encouragement!
              </P1>
            </Div>
            <Div mb={4}>
              <H3 mb={1}>Include a caption in your video post</H3>
              <P1>
                Be sure to include a caption with your videos. What was fun
                about the routine? What do you need help with? This group is all
                about sharing your dance journey, so tell us about it!
              </P1>
            </Div>
            <Div mb={4}>
              <H3 mb={1}>Post your video directly</H3>
              <P1>
                When posting a video, please upload it directly. Don’t post
                links to your personal social media accounts. This isn’t the
                place to ask for likes and followers. Let’s keep the vibe
                authentic and friendly!
              </P1>
            </Div>
            <Div mb={4}>
              <H3>Any hateful, mean-spirited, or spam posts will be removed</H3>
            </Div>
            {showAgreeButton && (
              <React.Fragment>
                <Button mb={3} onClick={handleClick}>
                  I Agree
                </Button>
                <P2 color="monochrome.7">
                  By clicking “I agree”, you acknowledge that you agree to
                  STEEZY&apos;s{" "}
                  <a href={`${env("PUBLIC_URL")}/terms`}>
                    <SpanLink>Terms of Service</SpanLink>
                  </a>{" "}
                  and Community Guidelines. Please be sure not to violate
                  others&apos; copyright or privacy rights.
                </P2>
              </React.Fragment>
            )}
          </LeftPane>
          <ScreenXl>
            <RightPane
              imageUrl={`${env(
                "PUBLIC_ASSET_PATH"
              )}/challenges/${CONTENT_SLUG}.jpg?h=750`}
            />
          </ScreenXl>
        </Wrapper>
      </StyledDialog>
    );
  },
  modalShouldRefresh
);

export default TermsAndConditionsDialog;
