import { cloneDeep, set } from "lodash";
import { MutationDataOptions, useMutation } from "@apollo/client";
import { USER_ACKNOWLEDGE_CONTENT } from "graphql/challenges/mutations";
import { useGetAcknowledgedContent } from "../hooks/useGetAcknowledgedContent";

interface Props {
  options?: MutationDataOptions;
}

export function useUserAcknowledgeContent({ options }: Props = {}) {
  const { meData } = useGetAcknowledgedContent({
    fetchPolicy: "cache-first",
  });
  const [acknowledgeContentMutation, acknowledgeContentResults] = useMutation(
    USER_ACKNOWLEDGE_CONTENT,
    options
  );

  return {
    acknowledgeContent: ({
      module,
      slug,
    }: {
      module: string;
      slug: string;
    }) => {
      const clonedProgress = cloneDeep(meData?.progress);
      set(
        clonedProgress,
        "acknowledgedContent.challenges.terms-and-conditions",
        new Date().toISOString()
      );

      acknowledgeContentMutation({
        variables: {
          input: {
            module,
            slug,
          },
        },
        optimisticResponse: {
          updateUser: {
            __typename: "User",
            id: meData?.id,
            progress: clonedProgress,
          },
        },
      });
    },
    acknowledgeContentResults,
  };
}
